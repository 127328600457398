<template>
  <div class="source px-16 bg-color pb-50 pt-16">
    <Navbar title="线路信息" />
    <Empty
      v-if="isStoped"
      imgWidth="0.84rem"
      colorBlack
      imgHeight="0.78rem"
      :image="stopImg"
      description="该项目已停用"
    >
      <template #extra>
        <Button @click="backSoruceList" class="mt-42" size="normal" type="primary">返回货源大厅</Button>
      </template>
    </Empty>
    <div v-else-if="dataList.length">
      <div v-for="(item, index) in dataList" :key="index" @click="() => handleJump(item)">
        <Scard :cardData="item" />
      </div>
    </div>
    <Empty v-else imgWidth="0.84rem" imgHeight="0.78rem" :image="emptyImg" description="暂无运单" />
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api';
import Scard from '../source/components/card';
import { useRouter } from '@/utils/compsitionHack';
import ClientApp from '@/client/index';
import { Button } from 'vant';
import { useRouterQuery } from '@hook/useRouterQuery';
import { apiGetSourceList } from '@/api/source';

export default defineComponent({
  name: 'Source',
  components: {
    Scard,
    Button
  },
  setup() {
    const dataList = ref([]);
    const router = useRouter();
    const isStoped = ref(false);
    const emptyImg = require('@/assets/htkb.png');
    const stopImg = require('@/assets/source-stop.png');
    const query = useRouterQuery();
    const handleJump = item => {
      router.push({
        path: `source/detail?id=${item.id}`
      });
    };
    const backSoruceList = () => {
      ClientApp.jumpNativePage(2); // 跳转到货源列表
    };

    apiGetSourceList({
      param: '',
      projectId: query.projectId,
      userLat: query.userLat || 120.2,
      userLng: query.userLng || 30.3
    })
      .then(res => {
        dataList.value = res.data;
      })
      .catch(err => {
        console.log(err);
      });
    return {
      isStoped,
      emptyImg,
      stopImg,
      dataList,
      backSoruceList,
      handleJump
    };
  }
});
</script>
<style lang="less">
.source {
  min-height: 100%;
}
</style>
